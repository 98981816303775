<template>
  <div>
    <grid-table
        :filters="filters"
        :columns="columns"
        :title="title"
        :url-update="urlUpdate"
        :sort-fields="sortFields"
        :filter-fields="filterFields"
        :refresh-period="refreshPeriod"
        :server-params="serverParams"
        :pagination-options="paginationOptions"
        :visibleFields="visibleFields"
    >

      <template #custom-filter-wrap="data">

      </template>

      <template #table-row="props">
          <span v-if="props.column.field == 'node'">
            <a href="#" title="Node"
               @click="props.openPopup('/node/tabs', {id: props.row.node, tab:'view', row: props.row},  $event, )">{{
                props.row.node
              }} </a>
          </span>
            <span v-else-if="props.column.field == 'task'">
            <a href="#" title="Task"
               @click="props.openPopup('/task/tabs', {node: props.row.node,task: props.row.task, tab:'view', row: props.row},  $event )">{{
                props.row.task
              }} </a>
            </span>
            <span v-else-if="props.column.field == 'action'">
              <a href="#"
                 @click="props.openPopup('tabs',{id: props.row.id, tab:'view', row: props.row},  $event)"
                 title="Детально">  <span class="bi bi-eye"></span></a>
            </span>
            <span v-else>
                {{ props.formattedRow[props.column.field] }}
          </span>
      </template>



    </grid-table>

  </div>
</template>

<script>
import GridTable from "./GridTableRemote.vue";


export default {
  name: "GridTableBackup",
  props: {
    filters: Object,
  },
  components: {
    GridTable
  },
  data()
  {
    return {
      visibleFields:['id','node','task','tag', 'nodeBytes','nodeInodes', 'nodeFiles', 'nodeDirs', 'timeStarted', 'timeUploaded', 'timeBorgFinish' ],
      filterFields:['id','node','task','tag', 'nodeBytes','nodeInodes', 'nodeFiles', 'nodeDirs', 'timeStarted', 'timeUploaded', 'timeBorgFinish' ],
      sortFields:['id','node','task','tag', 'nodeBytes','nodeInodes', 'nodeFiles', 'nodeDirs', 'timeStarted', 'timeUploaded', 'timeBorgFinish' ],
      columns: [
        {
          label: 'ID',
          field: 'id',

        },
        {
          label: 'Node',
          field: 'node',
        },
        {
          label: 'Task',
          field: 'task',
        },
        {
          label: 'Tag',
          field: 'tag',
        },
        {
          label: 'Node Bytes',
          field:'nodeBytes',
          formatFn: (value) => {
            return Math.round(value / Math.pow(1024, 3) * 100) / 100 + " GB";
          },
        },
        {
          label: 'Node Inodes',
          field: 'nodeInodes',
        },
        {
          label: 'Node Files',
          field: 'nodeFiles',
        },
        {
          label: 'Node Dirs',
          field: 'nodeDirs',
        },
        {
          label: 'Time Started',
          field: 'timeStarted',
        },
        {
          label: 'Time Uploaded',
          field:'timeUploaded',
          formatFn: (value) =>  {
            return value||'NULL'
          },
        },
        {
          label: 'Time Borg Finish',
          field:'timeBorgFinish',
          formatFn: (value) =>  {
            return value||'NULL'
          },
        },

        {
          html: true,
          field: 'action',
          tdClass: 'action-link',
        }
      ],
      urlUpdate: '/backup/get-data-ajax',
      title: 'Backup',
      refreshPeriod: 0,
      paginationOptions: {
        enabled: true,
        mode: 'records',
        perPage: 10,
      },
      serverParams: {
        columnFilters: {},

      },

    }
  },

  methods: {

    updateFilter(data, event)
    {
      if (event.target.attributes['data-name'].value)
      {
         data.serverParams.columnFilters[event.target.attributes['data-name'].value]=event.target.value;
         data.loadItems();
      }
    },
  },

}


</script>

<style scoped>
.custom-filter {
  width: 100%;
  border: 0;
  outline: 0;
  border-radius: 5px;
  padding: 8px 5px;
}


</style>