<template>
  <div class="checkselect" v-click-outside="hide">
    <div class="checkselect-control" @click="checkSelectControlClick" >
      <select class="form-control">
        <option>{{checkMessage}}</option>
      </select>
      <div class="checkselect-over"></div>
    </div>

    <div class="checkselect-popup" :style="{display: displayCheckPopup}" >

      <label v-for="item in items"
             :key="item.key">
        <input v-model="selectItems" :value="item.key" type="checkbox" >
        <span>{{ item.value }}</span>
      </label>

    </div>
  </div>
</template>

<script>
export default {
name: "CheckSelect",
  emits: ['update:selectItems'],
  props:{

    items: {
      default:[],
    },
    selectItems:{
      default:[],
    },
  },
  data()
  {
    return {
      displayCheckPopup:'none',
    }
  },
  methods:{
    checkSelectControlClick()
    {
      this.displayCheckPopup=this.displayCheckPopup==='block'?'none':'block';
    },
    hide()
    {
      this.displayCheckPopup='none';
    }
  },
  computed:{
    checkMessage(){
      return "Вибрано: "+this.selectItems.length+"/"+this.items.length;
      //return this.selectItems.length?"Выбрано "+this.selectItems.length:'Выберите из списка';
    }
  },
  watch:{
    selectItems(newParams, oldParams)
    {
      this.$emit('update:selectItems', newParams)
    }

  },
}
</script>

<style scoped>

.checkselect {
  position: relative;
  display: inline-block;
  min-width: 150px;
  text-align: left;
}
.checkselect-control {
  position: relative;
  padding: 0 !important;
}
.checkselect-control select {
  position: relative;
  display: inline-block;
  width: 100%;
  margin: 0;
  padding-left: 5px;
  height: 30px;
}
.checkselect-over {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  cursor: pointer;
}
.checkselect-popup {

  box-sizing: border-box;
  margin: 0;
  padding: 0;
  width: 100%;
  height: auto;
  max-height: 200px;
  position: absolute;
  top: 100%;
  left: 0px;
  border: 1px solid #dadada;
  border-top: none;
  background: #fff;
  z-index: 9999;
  overflow: auto;
  user-select: none;
}
.checkselect label {
  position: relative;
  display: block;
  margin: 0;
  padding: 4px 6px 4px 25px;
  font-weight: normal;
  font-size: 1em;
  line-height: 1.1;
  cursor: pointer;
}
.checkselect-popup input {
  position: absolute;
  top: 5px;
  left: 8px;
  margin: 0 !important;
  padding: 0;
}
.checkselect-popup label:hover {
  background: #03a2ff;
  color: #fff;
}
.checkselect-popup fieldset {
  display: block;
  margin:  0;
  padding: 0;
  border: none;
}
.checkselect-popup fieldset input {
  left: 15px;
}
.checkselect-popup fieldset label {
  padding-left: 32px;
}
.checkselect-popup legend {
  display: block;
  margin: 0;
  padding: 5px 8px;
  font-weight: 700;
  font-size: 1em;
  line-height: 1.1;
}

</style>