<template>
  <div>
    <grid-table
        :filters="filters"
        :columns="columns"
        :title="title"
        :url-update="urlUpdate"
        :sort-fields="sortFields"
        :filter-fields="filterFields"
        :refresh-period="refreshPeriod"
    >

      <template #custom-filter-wrap>

      </template>

      <template   #table-row="props">
             <span v-if="props.column.field == 'node'">
              <a href="#" title="Node"
                 @click="props.openPopup('/node/tabs', {id: props.row.node, tab:'view', row: props.row},  $event, )">{{
                  props.row.node
                }} </a>
        </span>
          <span v-else-if="props.column.field == 'task'">
            <a href="#" title="Task"
               @click="props.openPopup('tabs', {node: props.row.node,task: props.row.task, tab:'backups', row: props.row},  $event )">{{
                props.row.task
              }} </a>
          </span>
          <span v-else-if="props.column.field == 'action'">
            <a href="#"
               @click="props.openPopup('tabs',{node: props.row.node,task: props.row.task, tab:'view', row: props.row},  $event)"
               title="Детально">  <span class="bi bi-eye"></span></a>
          </span>
          <span v-else-if="props.column.field == 'backupAll'" v-html="props.formattedRow[props.column.field]">   </span>
          <span v-else>
              {{ props.formattedRow[props.column.field] }}
        </span>
      </template>



    </grid-table>

  </div>
</template>




<script>

import GridTable from "./GridTableLocal.vue";

export default {
  name: "TaskGridTable",
  props: {
    filters:Object,
  },
  components: {
    GridTable
  },
  data()
  {
    return {

      columns: [
        {
          label: 'Node',
          field:'node',
        },
        {
          label: 'Task',
          field: 'task',
        },

        {
          label: 'Backup All',
          html:true,
          field: 'backupAll',
        },
        {
          label: 'All size | Size last item',
          field: (model) => {
            return Math.round(model.uniqueSize / Math.pow(1024, 3) * 100) / 100 + " GB | "+
                   Math.round(model.sizeLastBackup / Math.pow(1024, 3) * 100) / 100 + " GB";
          },

        },
        {
          label: 'Time Updated',
          field: 'timeUpdated',
        },

        {
          field:'action',
          tdClass: 'action-link',
        }
      ],
      urlUpdate: '/task/get-data-ajax',
      title:'Task',
      refreshPeriod:0,
      sortFields:['node', 'task', 'timeUpdated'],
      filterFields:['node', 'task', 'timeUpdated'],
    }
  },
  methods: {

    updateFilter( event)
    {
      if (event.target.attributes['data-name'].value)
      {
        // TODO Поки не знаю як згодувати ці значення таблиці
      }
    },
  },


}


</script>

<style scoped>

</style>