
<template>
  <div>
    <grid-table
        :filters="filters"
        :columns="columns"
        :title="title"
        :url-update="urlUpdate"
        :sort-fields="sortFields"
        :filter-fields="filterFields"
        :refresh-period="refreshPeriod"
        :server-params="serverParams"
        :pagination-options="paginationOptions"
    >


      <template #table-row="props">
        <span v-if="props.column.field == 'action'">
              <a href="#"
                 @click="props.openPopup('tabs',{id: props.row.id, tab:'view', row: props.row},  $event)"
                 title="Детально">  <span class="bi bi-eye"></span></a>
               <a href="#"
                  @click="props.openPopup('tabs',{id: props.row.id, tab:'edit', row: props.row},  $event)"
                  title="Редактировать">  <span class="bi bi-pencil"></span></a>
            </span>
        <span v-else>
                {{ props.formattedRow[props.column.field] }}
          </span>
      </template>



    </grid-table>

  </div>
</template>

<script>
import GridTable from "./GridTableLocal.vue";


export default {
  name: "ErrorsGridTable",
  props: {
    filters: {default: []},
  },
  components: {
    GridTable
  },
  data()
  {
    return {
      filterFields:['id','code','action','line','file','text','detail','timeCreate'],
      sortFields:['id','code','action','line','file','text','detail','timeCreate'],
      columns: [
           {
              label: 'Ids',
              field: 'id',
          },
           {
              label: 'Codes',
              field: 'code',
          },
           {
              label: 'Actions',
              field: 'action',
          },
           {
              label: 'Lines',
              field: 'line',
          },
           {
              label: 'Files',
              field: 'file',
          },
           {
              label: 'Texts',
              field: 'text',
          },
           {
              label: 'Details',
              field: 'detail',
          },
           {
              label: 'Time Creates',
              field: 'timeCreate',
          },
          
        {
            field: 'action',
            tdClass: 'action-link',
        }
      ],
      urlUpdate: '/error/get-data-ajax',
      title: 'Errors',
      refreshPeriod: 0,
      paginationOptions: {
        enabled: true,
        perPage: 30,
      },
      serverParams: {
        columnFilters: {},
      },

    }
  },

  methods: {
  },

}


</script>

<style scoped>

</style>