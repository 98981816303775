<template>
  <div>

    <div class="sub-menu" id="grid-view-filters">
      <div class="row-files sub-menu-title">
        <div>
          <span class="table-name">{{ title }}</span>
          <span class="table-description">{{ description }}</span>

        </div>
      </div>
      <refresh :refreshPeriod="refreshPeriod" :onRefresh="loadItems"></refresh>

      <div class="custom-filter-wrap">
        <slot name="custom-filter-wrap" :serverParams="_serverParams" :loadItems="loadItems"> </slot>
      </div>

      <div class="right-side">
        <div :class="loadingClass"></div>
         <div class="table-panel">
         <slot name="table-panel" :openPopup="openPopup"> </slot>
           <check-select :items="fields" v-model:select-items="_visibleFields" ></check-select>
        </div>
      </div>

    </div>
    <vue-good-table
        mode="local"
        :columns="_columns"
        :rows="rows"
        :sort-options="_sortOptions"
        :pagination-options="paginationOptions"
        :search-options="searchOptions"
        :isLoading="isLoading"
        :theme="theme"
        :max-height="maxHeight"
        :fixed-header="fixedHeader"
        :total-rows="totalRows"
        :style-class="styleClass"
        :line-numbers="lineNumbers"
        :responsive="responsive"
        :rtl="rtl"
        :row-style-class="rowStyleClass"
        :compact-mode="compactMode"
        :group-options="groupOptions"
        :select-options="selectOptions"
    >


      <template #table-row="props">
        <slot name="table-row" :row="props.row" :column="props.column" :formattedRow="props.formattedRow" :index="props.index"  :openPopup="openPopup" :delete="deleteRow">  </slot>
      </template>

      <template #table-column="props">
        <slot name="table-column" :column="props.column" >  </slot>
      </template>

      <template #column-filter="props">
        <slot  name="column-filter" :column="props.column">  </slot>
      </template>

    <!-- DOTO: Слот для пагінації, і параметри для нього треба допрацювати -->
      <template #pagination-bottom="props">
        <slot  name="pagination-bottom" :pageChanged="props.pageChanged" :perPageChanged="props.perPageChanged" :total="props.total" :key="props.key">  </slot>
      </template>

    </vue-good-table>
  </div>
</template>

<script>
import {VueGoodTable} from 'vue-good-table-next';
import axios from "axios";
import refresh from "./Refresh.vue";
import checkSelect from "./CheckSelect.vue"

export default {
  name: "GridTableLocal",
  props: {
    sortFields:Array,
    filterFields: {default:[]},
    visibleFields:Array,
    filters:{default:[]},
    title:String,
    urlUpdate:String,
    description:String,
    columns:Array,
    theme: {default:'primary-table'},
    isLoading: { default: null, type: Boolean },
    maxHeight: { default: null, type: String },
    fixedHeader: Boolean ,
    styleClass: { default: 'vgt-table bordered' },
    lineNumbers: Boolean,
    responsive: { default: true , type: Boolean },
    rtl: Boolean,
    rowStyleClass: { default: null, type: [Function, String] },
    compactMode: Boolean,
    groupOptions: {},
    selectOptions: {},
    sortOptions: {},
    paginationOptions: {
      default:{
        enabled: true,
        perPage: 20,
      }
    },
    searchOptions: {},
    refreshPeriod:{default:0}
  },
  components: {
    refresh,
    VueGoodTable,
    checkSelect
  },
  data()
  {
    let sortOptions={};
    let columns=[];
    let uri = window.location.search.substring(1);
    let params = new URLSearchParams(uri);
    let fields=[];
    let _visibleFields=[];

    if(!this.sortOptions){

      let sort=params.get('sort');

      if(sort) {
        let sortType=sort[0]==='-'?'desc':'asc';

        if(sortType==='desc'){
          sort=sort.replace('-','');
        }

        if(this.sortFields.indexOf(sort)!==-1){
          sortOptions.enabled=true;
          sortOptions.initialSortBy={field: sort, type: sortType};
        }
      }
    }else {
      sortOptions=this.sortOptions;
    }

    for (const key in this.columns) {
      let column=this.columns[key];

      column.hidden=this.visibleFields&&this.visibleFields.indexOf(column.field)===-1;
      column.key=key;


      if(this.sortFields.indexOf(column.field)===-1){
        column.sortable=false;
      }

      if(this.filterFields.indexOf(column.field)!==-1)
      {
        if(column.filterOptions===undefined)
          column.filterOptions={};

        column.filterOptions.enabled=true;
      }

      if(column.filterOptions){
        column.filterOptions.filterValue=params.get(column.field)||null

        if(this.filters[column.field]){
          column.filterOptions.filterDropdownItems=this.filters[column.field];
        }

        if(column.filterOptions.placeholder===undefined)
          column.filterOptions.placeholder=' ';

      }

      if(!column.label&&column.label===undefined){
        column.label=column.field.replace(/[A-Z]/g, " $&").trim();
      }
      if(!column.hidden)
        _visibleFields.push(key);

      fields.push({key:key, value:column.label});
      columns[key]=column;
    }

    return {
      rows:[],
      _sortOptions:sortOptions,
      _columns:columns,
      totalRows:0,
      loadingClass:'hidden',
      _visibleFields:_visibleFields,
      fields:fields,
    }

  },
  methods: {
    async loadItems(){
      if(this.urlUpdate){
        this.loadingClass = 'image-loader-ajax';

        try{
          var response = await axios({
            method: 'post',
            url: this.urlUpdate,
          });

          if(response.status===200){
            this.totalRows = response.data.totalRows;
            this.rows = response.data.rows;
          }

        }  finally {
          this.loadingClass = 'hidden';
        }
      }
    },

    openPopup(url,  data, event){
      if (event) {
        event.preventDefault()
      }

      RPopup.callUiDialog(url, data);
      return false
    },

    async deleteRow(url, id,index,event)
    {
      if (event) {
        event.preventDefault()
      }

      if(url){
        this.loadingClass = 'image-loader-ajax';

        try{
          var response = await axios({
            method: 'post',
            url:url,
            data:{
              'id':id
            },
          });

          if(response.data.result){
            this.totalRows --;
            this.rows.splice(index,1);
          }

        }  finally {
          this.loadingClass = 'hidden';
        }
      }
    }

  },
  watch:{
    _visibleFields(newParams, oldParams)
    {
      this.columns.forEach(column=>{
        column.hidden=newParams&&newParams.indexOf(column.key)===-1;
      });
    },


  },
  mounted(){
    this.loadItems();
  },
}


</script>

<style scoped>
.image-loader-ajax {
  background: url('/images/load.gif') no-repeat center;
  width: 33px;
  height: 33px;
  margin-left: auto;
}

</style>