<template>
  <div class="row-files table-refresh"  @click="clickRefresh">
    <div class="refresh grid-view-refresh">
      <img src="/images/refresh.png">
      <div v-if="refreshPeriod>0" id='refresh-circle' style='position: absolute; top:0; left:0; width: 100%; height: 100%;padding:2px'></div>
    </div>
    <span class="timer-refresh" v-if="refreshPeriod>0"><span :class="refreshTimeClass">{{refreshTime}} sec</span></span>

  </div>
</template>

<script>
export default {
  name: "refresh",
  props:{
    refreshPeriod:Number,
    onRefresh:Function,
  },
  data(){
    return {
      refreshTime:this.refreshPeriod,
      loading:false,
      refreshTimeClass:'',
    }
  },
  mounted() {
    let circle = document.getElementById('refresh-circle');
    if(!circle) return false;
    this.refreshTimeClass=this.refreshPeriod!==0?'':'hidden';
    this.circle = new ProgressBar.Circle(circle, {
      color: '#FCB03C',
      strokeWidth: 6,
      trailWidth: 2,
      duration: 50
    });
    this.circle.animate(1);
    setInterval(() => {
      if(this.refreshPeriod!==0&&!this.loading)
      {
        if(this.refreshTime<=0){
          this.clickRefresh();
        }
        else{
          this.refreshTime--
          this.circle.animate(1-Math.min(1, this.refreshTime / this.refreshPeriod));
        }
      }
    }, 1000)
  },
  methods:{
     clickRefresh:function ()
     {
       this.loading=true;

       Promise.resolve( this.onRefresh()).then(function(value) {
          this.start();
       }).catch(error=>{
          this.start();
       });
     },
    start:function ()
    {
      this.loading=false;
      this.refreshTime=this.refreshPeriod;
    }
  }

}
</script>

<style scoped>
.refresh {
  position: relative;
  background-color: #56c131;
  width: 34px;
  height: 34px;
  cursor: pointer;
  border-radius: 4px;
  float: left;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 15px;
}
.refresh> span {
  font: normal 11px/42px Arial;
  margin: -4px 0 0 3px;
  float: left;
  display: block;
}


</style>