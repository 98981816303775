<template>
  <div>
    <grid-table
        :filters="filters"
        :columns="columns"
        :title="title"
        :theme="theme"
        :url-update="urlUpdate"
        :sort-fields="sortFields"
        :pagination-options="paginationOptions"
        :refresh-period="refreshPeriod"
        :description="description"
        :visibleFields="visibleFields"
        :base-url="baseUrl"
    >

      <template #table-panel="props">
        <div class="table-panel-item">

          <a href="#"
             @click="props.openPopup('tab-update',{ 'isNew':1}, $event)"
             title="Створити"> <button class="btn btn-green plus-btn"> </button></a>
        </div>
      </template>

      <template #table-column="props">

      <span v-if="props.column.label =='ID'">
        <i class="bi bi-eye"></i> {{props.column.label}}
     </span>
        <span v-else>
        {{props.column.label}}
     </span>
      </template>



      <template   #table-row="props">
           <span v-if="props.column.field == 'id'">
            <a href="#" title="Node"
               @click="props.openPopup('/node/tabs', {id: props.row.id, tab:'view', row: props.row},  $event, )">{{
                props.row.id
              }} </a>
             </span>
              <span v-else-if="props.column.field == 'task'">
            <a :href="props.row.taskUrl" title="Task" >{{  props.row.task }} </a>
            </span>
              <span v-else-if="props.column.field == 'action'">
              <a href="#"
                 @click="props.openPopup('/node/tabs',{id: props.row.id, tab:'view', row: props.row},  $event)"
                 title="Детально">  <span class="bi bi-eye"></span></a>
              <a href="#"
                 @click="props.openPopup('/node/tabs',{id: props.row.id, tab:'edit', row: props.row},  $event)"
                 title="Редактировать">  <span class="bi bi-pencil"></span></a>

              <a href="#"
                 @click="alert(props.row.token,  $event)"
                 title="Токен">  <span class="bi bi-upc"></span></a>
            </span>
              <span v-else-if="props.column.field == 'backupAll'" v-html="props.formattedRow[props.column.field]">
            </span>
              <span v-else>
                {{ props.formattedRow[props.column.field] }}
          </span>
        </template>

      <template #pagination-bottom="props">

      </template>

    </grid-table>

  </div>
</template>

<script>
import GridTable from './GridTableLocal.vue';


export default {
  name: "NodeGridTable",
  props: {
    filters:Object,
  },
  components: {
    GridTable
  },
  data()
  {

    return {
      columns: [
        {
          label: 'ID',
          field:'id',
          filterOptions: {
            enabled: true,
          }
        },
        {

          field: 'task',
          filterOptions: {
            enabled: false
          }
        },
        {
          label: 'Backup All',
          field: 'backupAll',
          filterOptions: {
            enabled: false,
          }
        },
        {

          field: 'timeUpdated',
          filterOptions: {
            enabled: true
          }
        },
        {
          field:'action',
          label:'Action',
          sortable: false,
          tdClass: 'action-link',
          filterOptions:{
            enabled: false,
          }
        }
      ],
      urlUpdate: '/node/get-data-ajax',
      title:'Node',
      refreshPeriod:0,
      theme:'primary-table',
      sortFields:['task', 'id', 'timeUpdated'],
      description:'Description for node',
      paginationOptions:{
        enabled: true,
        mode: 'pages',
        perPageDropdownEnabled: true,
        perPage: 10,
      },

    }

  },
  methods: {
    alert(msg, event)
    {
      if (event) {
        event.preventDefault()
      }

     alert(msg);
      return false
    }
  },

}


</script>

<style scoped>


</style>