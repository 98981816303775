import {createApp} from "vue";
import BackupGridTable from './BackupGridTable.vue';
import TaskGridTable from './TaskGridTable.vue';
import NodeGridTable from './NodeGridTable.vue';


const derective = {
    beforeMount:function (el, binding, vnode, prevVnode) {
        el.event_ = function (event) {
            if (!(el == event.target || el.contains(event.target))) {
               binding.value(event);
            }
        };
        document.body.addEventListener('click',  el.event_)
    },
    unmounted (el) {
        document.body.removeEventListener('click',  el.event_)
    },
};



export function initBackupGridTable(selector, props)
{
    const app= createApp(BackupGridTable, props);
    app.directive('click-outside',derective)
    app.mount(selector);
    return app;
}

export function initTaskGridTable(selector, props)
{
    const app= createApp(TaskGridTable, props);
    app.directive('click-outside',derective)
    app.mount(selector);
    return app;
}
export function initNodeGridTable(selector, props)
{
    const app= createApp(NodeGridTable, props);
    app.directive('click-outside',derective)
    app.mount(selector);

    return app;
}

// BEGIN ErrorsGridTable
  import ErrorsGridTable from './ErrorsGridTable.vue';

export function initErrorsGridTable(selector, props){
   const app= createApp(ErrorsGridTable, props);
    app.directive('click-outside',derective)
   app.mount(selector);
   return app;
}
// END ErrorsGridTable